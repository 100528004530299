import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const getTheme = () => {
  return createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#f3a160',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#7B5DEF',
        contrastText: '#ffffff',
      },
      error: {
        main: '#d45a5a',
      },
      background: {
        default: `url("/background.png"), ${grey[100]}`,
        paper: grey[50],
      },
      text: {
        primary: grey[900],
        secondary: grey[600],
      },
    },
    typography: {
      fontFamily: 'Inter, Arial, sans-serif',
      h4: {
        fontWeight: 500,
        color: grey[900],
      },
      body1: {
        color: grey[800],
      },
      button: {
        color: '#ffffff',
        textTransform: 'none',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundImage: 'url("/background.png")',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            textTransform: 'none',
          },
          containedPrimary: {
            backgroundColor: '#f3a160',
            '&:hover': {
              backgroundColor: '#e58e50',
            },
          },
          containedSecondary: {
            backgroundColor: '#7B5DEF',
            '&:hover': {
              backgroundColor: '#414378',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: '#ffffff',
            padding: '24px',
            borderRadius: 5,
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
            border: '0.5px solid rgba(140, 140, 140, 0.2)',
          },
        },
        variants: [
          {
            props: { variant: 'card' },
            style: {
              backgroundColor: grey[50],
              padding: '24px',
              borderRadius: 16,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            },
          },
        ],
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 16,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            borderRadius: '50%',
            width: 40,
            height: 40,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#000000',
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& label.Mui-focused': {
              color: '#7B5DEF',
            },
          },
        },
      },
      MuiBox: {
        styleOverrides: {
          root: {
            '& .rbc-header': {
              fontWeight: '700 !important',
              textTransform: 'uppercase !important',
              letterSpacing: '0.05em !important',
              padding: '0.75rem !important',
              color: grey[900],
            },
            '& .rbc-month-view .rbc-header': {
              fontWeight: '700 !important',
              textTransform: 'uppercase !important',
            },
            '& .rbc-time-view .rbc-header': {
              fontWeight: '700 !important',
              textTransform: 'uppercase !important',
            },
            '& .rbc-time-header-content .rbc-header': {
              fontWeight: '700 !important',
              textTransform: 'uppercase !important',
            }
          },
        },
      },
    },
  });
};

export default getTheme;