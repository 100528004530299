import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Button, TextField, Box, CircularProgress, Divider, Collapse } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

// Microsoft icon component
const MicrosoftIcon = () => (
  <svg width="20" height="20" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0h10v10H10V0z" fill="#f25022"/>
    <path d="M0 0h10v10H0V0z" fill="#00a4ef"/>
    <path d="M10 10h10v10H10V10z" fill="#7fba00"/>
    <path d="M0 10h10v10H0V10z" fill="#ffb900"/>
  </svg>
);

// Google icon component
const GoogleIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
    <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
    <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
    <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
  </svg>
);

function Register() {
  const { register, microsoftSignIn, googleSignIn } = useAuth();
  const [email, setEmail] = useState('');
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [microsoftLoading, setMicrosoftLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState({
    minLength: false,
    number: false,
    upperCase: false,
    lowerCase: false,
    specialChar: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    setPasswordErrors(checkPasswordStrength(password));
  }, [password]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPasswordFields(email.length > 0);
    }, 300);

    return () => clearTimeout(timer);
  }, [email]);

  const checkPasswordStrength = (password) => ({
    minLength: password.length >= 8,
    number: /[0-9]/.test(password),
    upperCase: /[A-Z]/.test(password),
    lowerCase: /[a-z]/.test(password),
    specialChar: /[!@#$%^&*]/.test(password),
  });

  const handleGoogleSignUp = async () => {
    try {
      setGoogleLoading(true);
      setErrorMessage('');
      await googleSignIn();
    } catch (error) {
      setErrorMessage(error.message);
      console.error('Google auth error:', error);
    } finally {
      setGoogleLoading(false);
    }
  };

  const handleMicrosoftSignUp = async () => {
    try {
      setMicrosoftLoading(true);
      setErrorMessage('');
      await microsoftSignIn();
    } catch (error) {
      setErrorMessage(error.message);
      console.error('Microsoft auth error:', error);
    } finally {
      setMicrosoftLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setLoading(true);

    const errors = checkPasswordStrength(password);
    if (!errors.minLength || !errors.number || !errors.upperCase || !errors.lowerCase || !errors.specialChar) {
      setErrorMessage('Your password does not meet all the requirements.');
      setPasswordErrors(errors);
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      await register(email, password);
      navigate('/verify');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getStyle = (isValid) => ({
    color: isValid ? 'green' : 'red',
  });

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh', padding: '20px 0' }}>
      <Grid item xs={12} sm={8} md={4}>
        <Box textAlign="center" mb={5}>
          <Typography variant="h6" component="div" gutterBottom>
            Welcome to
          </Typography>
          <img
            src="/MD_logo.png"
            alt="Logo"
            style={{ width: '200px', height: 'auto' }}
          />
        </Box>

        <Card>
          <CardContent>
            <Typography variant="h5" component="div" fontWeight="bold" gutterBottom>
              Register
            </Typography>
            {errorMessage && (
              <Typography color="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Typography>
            )}

            {/* Google Sign Up Button */}
            <Button
              variant="outlined"
              fullWidth
              onClick={handleGoogleSignUp}
              disabled={googleLoading}
              sx={{
                mb: 2,
                height: '48px',
                textTransform: 'none',
                borderColor: '#8C8C8C',
                color: '#000000',
                '&:hover': {
                  borderColor: '#8C8C8C',
                  backgroundColor: '#f8f9fa'
                }
              }}
              startIcon={googleLoading ? null : <GoogleIcon />}
            >
              {googleLoading ? <CircularProgress size={24} /> : 'Sign up with Google'}
            </Button>

            {/* Microsoft Sign Up Button */}
            <Button
              variant="outlined"
              fullWidth
              onClick={handleMicrosoftSignUp}
              disabled={microsoftLoading}
              sx={{
                height: '48px',
                textTransform: 'none',
                borderColor: '#8C8C8C',
                color: '#000000',
                '&:hover': {
                  borderColor: '#8C8C8C',
                  backgroundColor: '#f8f9fa'
                }
              }}
              startIcon={microsoftLoading ? null : <MicrosoftIcon />}
            >
              {microsoftLoading ? <CircularProgress size={24} /> : 'Sign up with Microsoft'}
            </Button>

            {/* SSO Note */}
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
              Your sign-in method doesn't need to match your calendar source
            </Typography>

            {/* Divider */}
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 3, mb: 2 }}>
              <Divider sx={{ flex: 1 }} />
              <Typography variant="body2" sx={{ mx: 2, color: 'text.secondary' }}>
                or
              </Typography>
              <Divider sx={{ flex: 1 }} />
            </Box>

            <form onSubmit={handleSubmit}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <Collapse in={showPasswordFields} timeout={500}>
                <Box>
                  <TextField
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required={showPasswordFields}
                  />
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required={showPasswordFields}
                  />
                  <Box mt={2}>
                    <Typography variant="body2" component="div">
                      Password Requirements:
                      <ul style={{ paddingLeft: '20px', marginTop: '8px' }}>
                        <li style={getStyle(passwordErrors.minLength)}>At least 8 characters long</li>
                        <li style={getStyle(passwordErrors.number)}>At least one number</li>
                        <li style={getStyle(passwordErrors.upperCase)}>At least one uppercase letter</li>
                        <li style={getStyle(passwordErrors.lowerCase)}>At least one lowercase letter</li>
                        <li style={getStyle(passwordErrors.specialChar)}>At least one special character (!@#$%^&*)</li>
                      </ul>
                    </Typography>
                  </Box>
                </Box>
              </Collapse>

              <Box mt={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth
                  disabled={loading}
                  sx={{ height: '48px' }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Register'}
                </Button>
              </Box>
              <Box mt={2} textAlign="center">
                <Typography variant="body2">
                  Already have an account? <Link to="/login" style={{ color: '#6200ee', textDecoration: 'none' }}>Log in</Link>
                </Typography>
              </Box>
              <Box mt={2} textAlign="center">
                <Typography variant="body2" color="text.secondary">
                  By creating an account you agree to our{' '}
                  <a
                    href="https://www.meetdoris.com/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#6200ee', textDecoration: 'none' }}
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.meetdoris.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#6200ee', textDecoration: 'none' }}
                  >
                    Privacy Policy
                  </a>
                </Typography>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Register;