import React, { useState, useEffect } from 'react';
import confetti from 'canvas-confetti';
import {
  Button,
  TextField,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
  IconButton,
  Divider,
  Card,
  CardContent,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import axiosInstance from '../api/axiosInstance';

const COLORS = [
  '#F3A160', '#2E3060', '#C6D8AF', '#6CCFF6', '#9999C3',
  '#FFCC99', '#A3D9FF', '#FF9999', '#B5A1FF', '#FFE599'
];

function HabitDetailForm({ habit, onClose, refreshHabits }) {
  const { auth } = useAuth();
  const [habitName, setHabitName] = useState(habit?.name || '');
  const [requiresScheduling, setRequiresScheduling] = useState(habit?.requires_scheduling || true);
  const [estimatedMinutes, setEstimatedMinutes] = useState(habit?.estimated_minutes || '');
  const [windowStart, setWindowStart] = useState(habit?.window_start || '09:00');
  const [windowEnd, setWindowEnd] = useState(habit?.window_end || '17:00');
  const [selectedColor, setSelectedColor] = useState(habit?.hex_color || COLORS[0]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [skipReason, setSkipReason] = useState('');
  const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);

  useEffect(() => {
    if (habit) {
      setHabitName(habit.name);
      setRequiresScheduling(habit.requires_scheduling);
      setEstimatedMinutes(habit.estimated_minutes || '');
      setSelectedColor(habit.hex_color || COLORS[0]);
      setWindowStart(habit.window_start || '09:00');
      setWindowEnd(habit.window_end || '17:00');
    }
  }, [habit]);

  const fireConfetti = () => {
    // Fire confetti from the center
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });

    // Fire confetti from the left
    confetti({
      particleCount: 50,
      angle: 60,
      spread: 55,
      origin: { x: 0 }
    });

    // Fire confetti from the right
    confetti({
      particleCount: 50,
      angle: 120,
      spread: 55,
      origin: { x: 1 }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');

    if (!habitName.trim()) {
      setErrorMessage('Please provide a habit name.');
      setIsErrorModalOpen(true);
      return;
    }

    if (requiresScheduling && (!estimatedMinutes || parseInt(estimatedMinutes) <= 0)) {
      setErrorMessage('Please set valid estimated minutes for scheduled habits.');
      setIsErrorModalOpen(true);
      return;
    }

    if (windowStart >= windowEnd) {
      setErrorMessage('End time must be after start time.');
      setIsErrorModalOpen(true);
      return;
    }

    const habitData = {
      name: habitName,
      requires_scheduling: requiresScheduling,
      estimated_minutes: requiresScheduling ? parseInt(estimatedMinutes) : null,
      hex_color: selectedColor,
      window_start: windowStart,
      window_end: windowEnd,
    };

    try {
      await axiosInstance.put(`/api/habits/${habit.id}`, habitData);
      if (refreshHabits) refreshHabits();
      if (onClose) onClose();
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error saving the habit.');
      setIsErrorModalOpen(true);
    }
  };

  const handleComplete = async () => {
    try {
      await axiosInstance.post(`/api/habits/${habit.id}/complete`);
      fireConfetti();
      if (refreshHabits) refreshHabits();
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error completing the habit.');
      setIsErrorModalOpen(true);
    }
  };

  const handleSkip = async () => {
    if (!skipReason.trim()) {
      setErrorMessage('Please provide a reason for skipping.');
      setIsErrorModalOpen(true);
      return;
    }

    try {
      await axiosInstance.post(`/api/habits/${habit.id}/skip`, { reason: skipReason });
      setIsSkipModalOpen(false);
      setSkipReason('');
      if (refreshHabits) refreshHabits();
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error skipping the habit.');
      setIsErrorModalOpen(true);
    }
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/api/habits/${habit.id}`);
      setIsDeleteModalOpen(false);
      if (refreshHabits) refreshHabits();
      if (onClose) onClose();
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error deleting the habit.');
      setIsErrorModalOpen(true);
    }
  };

  return (
    <Box sx={{ maxHeight: '80vh', overflowY: 'auto', padding: 2 }}>
      {/* Stats Section */}
      <Card sx={{ mb: 3, bgcolor: 'rgba(0,0,0,0.02)' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Box display="flex" alignItems="center" gap={1}>
                <LocalFireDepartmentIcon color="error" />
                <Typography variant="body2">
                  Current Streak: {habit.current_streak}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box display="flex" alignItems="center" gap={1}>
                <EmojiEventsIcon color="primary" />
                <Typography variant="body2">
                  Best Streak: {habit.longest_streak}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body2">
                Total Completions: {habit.total_completions}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="body2">
                Times Skipped: {habit.skipped_count}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Action Buttons */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="success"
            fullWidth
            startIcon={<CheckCircleIcon />}
            onClick={handleComplete}
            sx={{ boxShadow: 'none' }}
          >
            Complete Today
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="warning"
            fullWidth
            startIcon={<SkipNextIcon />}
            onClick={() => setIsSkipModalOpen(true)}
            sx={{ boxShadow: 'none' }}
          >
            Skip Today
          </Button>
        </Grid>
      </Grid>


      <Divider sx={{ my: 3 }} />

      {/* Edit Form */}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Edit Habit</Typography>
              <IconButton
                color="error"
                onClick={() => setIsDeleteModalOpen(true)}
                title="Delete Habit"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* Original form fields */}
          <Grid item xs={12}>
            <TextField
              label="Habit Name"
              variant="outlined"
              fullWidth
              value={habitName}
              onChange={(e) => setHabitName(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant={requiresScheduling ? 'contained' : 'outlined'}
                onClick={() => setRequiresScheduling(true)}
                fullWidth
                sx={{ boxShadow: 'none' }}
              >
                Must Schedule Daily
              </Button>
              <Button
                variant={!requiresScheduling ? 'contained' : 'outlined'}
                onClick={() => setRequiresScheduling(false)}
                fullWidth
                sx={{ boxShadow: 'none' }}
              >
                Schedule If Available
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
              <AccessTimeIcon sx={{ mr: 1 }} />
              Time Window:
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <TextField
                type="time"
                value={windowStart}
                onChange={(e) => setWindowStart(e.target.value)}
                variant="outlined"
                sx={{ width: '45%' }}
                inputProps={{ step: 300 }}
              />
              <Typography>to</Typography>
              <TextField
                type="time"
                value={windowEnd}
                onChange={(e) => setWindowEnd(e.target.value)}
                variant="outlined"
                sx={{ width: '45%' }}
                inputProps={{ step: 300 }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Estimated Minutes"
              variant="outlined"
              fullWidth
              type="number"
              value={estimatedMinutes}
              onChange={(e) => setEstimatedMinutes(e.target.value)}
              required={requiresScheduling}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center">
                {COLORS.map((color) => (
                  <Box
                    key={color}
                    onClick={() => setSelectedColor(color)}
                    sx={{
                      width: 24,
                      height: 24,
                      backgroundColor: color,
                      borderRadius: '50%',
                      cursor: 'pointer',
                      border: selectedColor === color ? '2px solid black' : '2px solid transparent',
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Error Modal */}
      <Dialog open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsErrorModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Modal */}
      <Dialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        PaperProps={{ sx: { borderRadius: 2, padding: 1 } }}
      >
        <DialogTitle>Delete Habit</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this habit? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 2, justifyContent: 'space-between' }}>
          <Button onClick={() => setIsDeleteModalOpen(false)} color="inherit" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Skip Reason Modal */}
      <Dialog open={isSkipModalOpen} onClose={() => setIsSkipModalOpen(false)}>
        <DialogTitle>Skip Habit</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Please provide a reason for skipping this habit today:
          </DialogContentText>
          <TextField
            autoFocus
            label="Reason"
            fullWidth
            value={skipReason}
            onChange={(e) => setSkipReason(e.target.value)}
            multiline
            rows={2}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSkipModalOpen(false)}>Cancel</Button>
          <Button onClick={handleSkip} color="primary">Skip</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default HabitDetailForm;