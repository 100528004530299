import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Grid,
  Alert,
  ButtonGroup,
  Button
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import axiosInstance from "../../api/axiosInstance";

const CapacityChart = () => {
  const [data, setData] = useState(null);
  const [viewRange, setViewRange] = useState('1m');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const TIME_RANGES = {
    '1w': 7,
    '2w': 14,
    '1m': 30
  };

  // Modern color scheme
  const COLORS = {
    used: '#4F46E5',    // Deep indigo for used capacity
    free: '#10B981'     // Emerald for free capacity
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: response } = await axiosInstance.get('/api/analytics/capacity');
        setData(response);
        setError(null);
      } catch (err) {
        console.error('Error fetching capacity data:', err);
        setError('Failed to load capacity data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleRangeChange = (newRange) => {
    setViewRange(newRange);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={400}>
        <CircularProgress sx={{ color: COLORS.used }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Alert
          severity="error"
          sx={{
            width: '100%',
            backgroundColor: 'rgba(253, 237, 237, 0.8)',
            '.MuiAlert-icon': { color: '#d32f2f' }
          }}
        >
          {error}
        </Alert>
      </Box>
    );
  }

  if (!data || !data.days) {
    return (
      <Box sx={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ color: '#666' }}>No data available.</Typography>
      </Box>
    );
  }

  const daysToShow = TIME_RANGES[viewRange] || 30;
  const displayedData = data.days.slice(0, daysToShow).map(day => {
    const total = day.total_capacity_minutes;
    const usedPercentage = (day.used_capacity_minutes / total) * 100;
    const freePercentage = 100 - usedPercentage;

    return {
      date: day.date,
      used: Math.round(usedPercentage * 10) / 10,
      free: Math.round(freePercentage * 10) / 10
    };
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const used = payload.find(p => p.dataKey === 'used')?.value || 0;
      const free = payload.find(p => p.dataKey === 'free')?.value || 0;

      return (
        <Paper
          elevation={0}
          sx={{
            p: 1.5,
            border: '1px solid #e0e0e0',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            borderRadius: 2,
            backdropFilter: 'blur(8px)'
          }}
        >
          <Typography variant="subtitle2" sx={{ color: '#1a1a1a' }}>
            {label}
          </Typography>
          <Typography variant="body2" sx={{ color: '#666' }}>
            Used: {used.toFixed(1)}%
          </Typography>
          <Typography variant="body2" sx={{ color: '#666' }}>
            Free: {free.toFixed(1)}%
          </Typography>
          <Typography variant="body2" sx={{ color: '#666', fontWeight: 500 }}>
            Total: 100%
          </Typography>
        </Paper>
      );
    }
    return null;
  };

  const CustomLegend = ({ payload }) => {
    return (
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'center',
        mt: 2
      }}>
        {payload.map((entry) => (
          <Box
            key={entry.value}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Box
              sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: entry.color
              }}
            />
            <Typography variant="body2" sx={{ color: '#666' }}>
              {entry.value}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 4,
        borderRadius: 4,
        backgroundColor: '#ffffff',
        border: '1px solid #f0f0f0'
      }}
    >
      <Typography
        variant="h6"
        sx={{
          mb: 3,
          color: '#1a1a1a',
          fontWeight: 500
        }}
      >
        Capacity overview
      </Typography>

      <Box
        sx={{
          mb: 3,
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <ButtonGroup
          variant="outlined"
          sx={{
            '& .MuiButton-root': {
              borderColor: '#e0e0e0',
              color: '#666',
              textTransform: 'none',
              '&.Mui-selected, &:hover': {
                borderColor: COLORS.used,
                backgroundColor: 'rgba(79, 70, 229, 0.08)'
              }
            }
          }}
        >
          {Object.entries(TIME_RANGES).map(([range, days]) => (
            <Button
              key={range}
              variant={viewRange === range ? 'contained' : 'outlined'}
              onClick={() => handleRangeChange(range)}
              sx={{
                ...(viewRange === range && {
                  backgroundColor: COLORS.used + '!important',
                  color: 'white!important'
                })
              }}
            >
              {range === '1w' ? '1 Week' : range === '2w' ? '2 Weeks' : '1 Month'}
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      <Box sx={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
          <BarChart data={displayedData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
            <XAxis
              dataKey="date"
              tick={{ fill: '#666' }}
              axisLine={{ stroke: '#e0e0e0' }}
            />
            <YAxis
              domain={[0, 100]}
              unit="%"
              tick={{ fill: '#666' }}
              axisLine={{ stroke: '#e0e0e0' }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend content={<CustomLegend />} />
            <Bar
              dataKey="used"
              stackId="a"
              fill={COLORS.used}
              name="Used Capacity"
              radius={[4, 4, 0, 0]}
            />
            <Bar
              dataKey="free"
              stackId="a"
              fill={COLORS.free}
              name="Free Capacity"
              radius={[4, 4, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default CapacityChart;