import React, { useState, useEffect } from 'react';
import {
  Button,
  Tooltip,
  TextField,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Checkbox from '@mui/material/Checkbox';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth
import { Link } from 'react-router-dom'; // Import Link for upgrade link

// New color palette
const COLORS = [
    '#B5A1FF', // Light violet
  '#FFE599', // Soft yellow
    '#FF9999', // Light red
      '#6CCFF6', // Custom cyan blue
  '#F3A160', // Custom orange
  '#2E3060', // Custom dark blue
  '#C6D8AF', // Custom light green
  '#6CCFF6', // Custom cyan blue
  '#9999C3', // Custom soft purple
  '#FFCC99', // Light peach
  '#A3D9FF', // Soft sky blue
  '#FF9999', // Light red
];

function formatDateTimeLocal(dateString) {
  if (!dateString) return ''; // Handle empty or undefined values
  const date = new Date(dateString);
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60 * 1000); // Adjust to local time
  return localDate.toISOString().slice(0, 16); // Truncate to "YYYY-MM-DDTHH:MM"
}

function ProjectForm({ onClose, refreshProjects, project }) {
  const { auth } = useAuth(); // Access auth object
  const [projectName, setProjectName] = useState(project ? project.name : '');
  const [deadline, setDeadline] = useState(project ? project.deadline : '');
  const [startTime, setStartTime] = useState(project ? project.start_time : '');
  const [maxSitting, setMaxSitting] = useState(project ? project.max_sitting_length : '');
  const [taskName, setTaskName] = useState('');
  const [taskHours, setTaskHours] = useState('');
  const [taskMinutes, setTaskMinutes] = useState('');
  const [tasks, setTasks] = useState(project ? project.tasks : []);
  const [isEditing, setIsEditing] = useState(null);
  const [editHours, setEditHours] = useState('');
  const [editMinutes, setEditMinutes] = useState('');
  const [selectedColor, setSelectedColor] = useState(project ? project.color : COLORS[0]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const MAX_FREE_TASKS = 3; // Set maximum tasks for free users

  useEffect(() => {
    if (project) {
      setProjectName(project.name);
      setStartTime(formatDateTimeLocal(project.start_time)); // Format for datetime-local input
      setDeadline(formatDateTimeLocal(project.deadline));    // Format for datetime-local input
      setMaxSitting(project.max_sitting_length || '');
      setSelectedColor(project.color || COLORS[0]);

      if (project.tasks) {
        setTasks(
          project.tasks.map((task) => ({
            name: task.name,
            hours: task.estimated_hours,
            minutes: task.estimated_minutes,
            completed: task.completed || false,
          }))
        );
      }
    }
  }, [project]);

  const calculateCompletionPercentage = () => {
    const totalMinutes = tasks.reduce(
      (total, task) => total + (parseInt(task.hours) * 60 + parseInt(task.minutes)), 0
    );

    const completedMinutes = tasks.reduce(
      (completed, task) => completed + (task.completed ? (parseInt(task.hours) * 60 + parseInt(task.minutes)) : 0),
      0
    );

    return totalMinutes > 0 ? (completedMinutes / totalMinutes) * 100 : 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrorMessage('');

    const now = new Date();
    const start = new Date(startTime);
    const end = new Date(deadline);

    // Added validation for maxSitting
    if (!maxSitting || parseInt(maxSitting) <= 0) {
      setErrorMessage('Please set a valid value for Single Event Max (minutes).');
      setIsErrorModalOpen(true);
      return;
    }

    if (!project && start <= now) {
      setErrorMessage('Start time must be in the future for new projects.');
      setIsErrorModalOpen(true);
      return;
    }

    if (end <= now) {
      setErrorMessage('Deadline must be in the future.');
      setIsErrorModalOpen(true);
      return;
    }

    if (end <= start) {
      setErrorMessage('Deadline must be after the start time.');
      setIsErrorModalOpen(true);
      return;
    }

    const projectData = {
      name: projectName,
      start_time: startTime,
      deadline: deadline,
      max_sitting_length: maxSitting,
      color: selectedColor,
      tasks: tasks.map((task, index) => ({
        name: task.name,
        estimated_hours: task.hours,
        estimated_minutes: task.minutes,
        completed: task.completed,
        order: index + 1,
      })),
    };

    try {
      if (project) {
        await axiosInstance.put(`/api/projects/${project.id}`, projectData);
      } else {
        await axiosInstance.post('/api/project-create', projectData);
      }

      if (refreshProjects) {
        refreshProjects();
      }

      if (onClose) {
        onClose();
      }
    } catch (error) {
      setErrorMessage('Error saving the project. Please try again.');
      setIsErrorModalOpen(true);
    }
  };

  const handleAddTask = () => {
    // Check if user is not subscribed and has reached the task limit
    if (!auth.isSubscribed && tasks.length >= MAX_FREE_TASKS) {
      setErrorMessage(`Free users can add up to ${MAX_FREE_TASKS} tasks. Please upgrade to add more.`);
      setIsErrorModalOpen(true);
      return;
    }

    if (taskName.trim() !== '' && (taskHours !== '' || taskMinutes !== '')) {
      const newTask = {
        name: taskName,
        hours: taskHours || 0,
        minutes: taskMinutes || 0,
        completed: false,
      };
      setTasks([...tasks, newTask]);
      setTaskName('');
      setTaskHours('');
      setTaskMinutes('');
    } else {
      setErrorMessage('Please provide a task name and duration.');
      setIsErrorModalOpen(true);
    }
  };

  const handleDeleteTask = (index) => {
    const newTasks = [...tasks];
    newTasks.splice(index, 1);
    setTasks(newTasks);
  };

  const handleEditTask = (index) => {
    setIsEditing(index);
    setEditHours(tasks[index].hours);
    setEditMinutes(tasks[index].minutes);
  };

  const handleSaveTask = (index) => {
    const updatedTasks = [...tasks];
    updatedTasks[index] = {
      ...updatedTasks[index],
      hours: editHours || 0,
      minutes: editMinutes || 0,
    };
    setTasks(updatedTasks);
    setIsEditing(null);
  };

  const handleTaskCompletionToggle = (index) => {
    const updatedTasks = [...tasks];
    updatedTasks[index].completed = !updatedTasks[index].completed;
    setTasks(updatedTasks);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedTasks = Array.from(tasks);
    const [movedTask] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, movedTask);

    setTasks(reorderedTasks);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  return (
    <Box
      sx={{
        maxHeight: '80vh',  // Adjust this value as needed
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: 2,         // Optional padding
      }}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Project Details */}
          <Grid item xs={12}>
            <TextField
              label="Project Name"
              variant="outlined"
              fullWidth
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Start Time"
              variant="outlined"
              fullWidth
              type="datetime-local"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Deadline"
              variant="outlined"
              fullWidth
              type="datetime-local"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Tooltip title="Maximum time that a single calendar event can take up in minutes." arrow>
              <TextField
                label="Single Event Max. (minutes)"
                variant="outlined"
                fullWidth
                type="number"
                value={maxSitting}
                onChange={(e) => setMaxSitting(e.target.value)}
              />
            </Tooltip>
          </Grid>

          {/* Color Picker - Centered */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center">
                {COLORS.map((color) => (
                  <Box
                    key={color}
                    onClick={() => handleColorChange(color)}
                    sx={{
                      width: 24,
                      height: 24,
                      backgroundColor: color,
                      borderRadius: '50%',
                      cursor: 'pointer',
                      border: selectedColor === color ? '2px solid black' : '2px solid transparent',
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Grid>

          {/* Progress Bar */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1, marginBottom: 2 }}>
              {`Project ${Math.round(calculateCompletionPercentage())}% Completed`}
            </Box>
            <LinearProgress variant="determinate" value={calculateCompletionPercentage()} />
          </Grid>

          {/* Task Counter and Upgrade Link */}
          {!auth.isSubscribed && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ marginRight: '8px' }}>
                {`${tasks.length}/${MAX_FREE_TASKS} Tasks`}
              </Typography>
              <Link to="/subscribe" style={{ textDecoration: 'none' }}>
                <Typography variant="body2" color="secondary" sx={{ textDecoration: 'underline' }}>
                  Upgrade for more
                </Typography>
              </Link>
            </Grid>
          )}

          {/* Task Fields */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Task Name"
              variant="outlined"
              fullWidth
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              label="Hours"
              variant="outlined"
              fullWidth
              type="number"
              value={taskHours}
              onChange={(e) => setTaskHours(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              label="Minutes"
              variant="outlined"
              fullWidth
              type="number"
              value={taskMinutes}
              onChange={(e) => setTaskMinutes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleAddTask}
              fullWidth
              startIcon={<AddIcon />}
              disabled={!auth.isSubscribed && tasks.length >= MAX_FREE_TASKS} // Disable button if limit reached
            >
              {(!auth.isSubscribed && tasks.length >= MAX_FREE_TASKS) ? 'Upgrade to add more tasks' : 'Add Task'}
            </Button>
          </Grid>

          {/* Task List with Edit, Save, Checkbox for Completion */}
          <Grid item xs={12}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="tasks">
                {(provided) => (
                  <List {...provided.droppableProps} ref={provided.innerRef}>
                    {tasks.map((task, index) => (
                      <Draggable key={index} draggableId={`task-${index}`} index={index}>
                        {(provided) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            divider
                          >
                            <Checkbox
                              checked={task.completed}
                              onChange={() => handleTaskCompletionToggle(index)}
                            />
                            {isEditing === index ? (
                              <Box sx={{ display: 'flex', gap: 2, width: '90%' }}>
                                <TextField
                                  label="Hours"
                                  type="number"
                                  value={editHours}
                                  onChange={(e) => setEditHours(e.target.value)}
                                />
                                <TextField
                                  label="Minutes"
                                  type="number"
                                  value={editMinutes}
                                  onChange={(e) => setEditMinutes(e.target.value)}
                                />
                                <Button
                                  onClick={() => handleSaveTask(index)}
                                  startIcon={<SaveIcon />}
                                  variant="contained"
                                  color="primary"
                                >
                                  Save
                                </Button>
                              </Box>
                            ) : (
                              <Box sx={{ flexGrow: 1 }}>
                                <ListItemText
                                  primary={task.name}
                                  secondary={`${task.hours}h ${task.minutes}m`}
                                  sx={{ textDecoration: task.completed ? 'line-through' : 'none' }}
                                />
                              </Box>
                            )}
                            <ListItemSecondaryAction>
                              <IconButton edge="end" onClick={() => handleEditTask(index)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTask(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="secondary" fullWidth>
              {project ? 'Update Project' : 'Add Project'}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Error Modal */}
      <Dialog
        open={isErrorModalOpen}
        onClose={handleCloseErrorModal}
        PaperProps={{
          sx: {
            borderRadius: 12, // Rounded corners for modal
            padding: '16px', // Padding inside modal
          },
        }}
      >
        <DialogTitle sx={{ marginBottom: '16px' }}>Error</DialogTitle> {/* Padding below title */}
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ProjectForm;