import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Grid,
  useTheme,
  Alert
} from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend
} from 'recharts';
import axiosInstance from '../../api/axiosInstance';

const TimeDistributionChart = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  // Modern, softer color palette
  const COLORS = {
    'Projects': '#4F46E5',    // Deep indigo - substantial work
    'Tasks': '#8B5CF6',      // Purple - discrete work items
    'Habits': '#10B981',     // Emerald - recurring activities
    'Calendar Events': '#F59E0B'  // Amber - scheduled time blocks
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: response } = await axiosInstance.get('/api/analytics/time_distribution');
        setData(response);
        setError(null);
      } catch (error) {
        console.error('Error fetching time distribution:', error);
        setError('Failed to load time distribution data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={400}>
        <CircularProgress sx={{ color: '#4169E1' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Alert
          severity="error"
          sx={{
            width: '100%',
            backgroundColor: 'rgba(253, 237, 237, 0.8)',
            '.MuiAlert-icon': { color: '#d32f2f' }
          }}
        >
          {error}
        </Alert>
      </Box>
    );
  }

  const formatMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours === 0) return `${remainingMinutes}m`;
    if (remainingMinutes === 0) return `${hours}h`;
    return `${hours}h ${remainingMinutes}m`;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Paper
          elevation={0}
          sx={{
            p: 1.5,
            border: '1px solid #e0e0e0',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            borderRadius: 2,
            backdropFilter: 'blur(8px)'
          }}
        >
          <Typography variant="subtitle2" sx={{ color: '#1a1a1a' }}>
            {data.name}
          </Typography>
          <Typography variant="body2" sx={{ color: '#666' }}>
            {formatMinutes(data.minutes)}
          </Typography>
          <Typography variant="body2" sx={{ color: '#666' }}>
            {data.percentage}% of total
          </Typography>
        </Paper>
      );
    }
    return null;
  };

  const CustomLegend = ({ payload }) => {
    return (
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'center',
        mt: 2
      }}>
        {payload.map((entry) => (
          <Box
            key={entry.value}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Box
              sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: entry.color
              }}
            />
            <Typography variant="body2" sx={{ color: '#666' }}>
              {entry.value}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const StatCard = ({ title, value, color }) => (
    <Box
      sx={{
        p: 2,
        borderRadius: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        border: '1px solid #f0f0f0',
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
        }
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color,
          fontWeight: 500,
          mb: 0.5,
          fontSize: '1.25rem'
        }}
      >
        {formatMinutes(value)}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: '#666',
          fontSize: '0.875rem'
        }}
      >
        {title}
      </Typography>
    </Box>
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: 'white',
            height: '100%'
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 4,
              textAlign: 'left',
              color: '#1a1a1a',
              fontWeight: 500
            }}
          >
            My time breakdown
          </Typography>

          <Box sx={{ width: '100%', height: 400 }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={data.categories}
                  dataKey="minutes"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius="60%"
                  outerRadius="80%"
                  paddingAngle={2}
                >
                  {data.categories.map((entry) => (
                    <Cell
                      key={entry.name}
                      fill={COLORS[entry.name]}
                      stroke="none"
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend content={<CustomLegend />} />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: 4,
            backgroundColor: 'white',
            height: '100%'
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 4,
              color: '#666',
              fontWeight: 500
            }}
          >
            Time Summary
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
          >
            {data.categories.map((category) => (
              <StatCard
                key={category.name}
                title={category.name}
                value={category.minutes}
                color={COLORS[category.name]}
              />
            ))}

            <Box
              sx={{
                mt: 'auto',
                pt: 2,
                borderTop: '1px solid #f0f0f0'
              }}
            >
              <StatCard
                title="Total Time"
                value={data.total_minutes}
                color="#1a1a1a"
              />
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TimeDistributionChart;