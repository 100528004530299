import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  IconButton,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Layout from '../components/Layout';
import { useAuth } from '../contexts/AuthContext';
import axiosInstance from '../api/axiosInstance';
import HabitForm from '../components/HabitForm';
import HabitDetailForm from '../components/HabitDetailForm';

const HabitCard = ({ habit, onComplete, onEdit, onSkip }) => {
  const getTimeWindowDisplay = () => {
    if (!habit.requires_scheduling) return "Flexible Timing";
    return `${habit.window_start || '09:00'} - ${habit.window_end || '17:00'}`;
  };

  return (
    <Card sx={{
      borderRadius: 2,
      boxShadow: 1,
      position: 'relative',
      overflow: 'visible',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '14px',
        bottom: '14px',
        right: '-1px',
        width: '4px',
        backgroundColor: habit.hex_color || '#78C2FF',
        borderRadius: '2px',
      }
    }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: habit.hex_color || '#4CAF50',
                borderRadius: '50%',
                mr: 1,
              }}
            />
            <Typography variant="h6" component="div">
              {habit.name}
            </Typography>
          </Box>
          <IconButton size="small" onClick={() => onEdit(habit)}>
            <EditIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 1,
            mb: 2,
            bgcolor: 'rgba(0,0,0,0.02)',
            p: 1.5,
            borderRadius: 1
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <LocalFireDepartmentIcon
              sx={{ color: '#ff4d4d' }}
              fontSize="small"
            />
            <Typography variant="body2" color="textSecondary">
              {habit.current_streak || 0} days
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <EmojiEventsIcon
              sx={{ color: '#ffd700' }}
              fontSize="small"
            />
            <Typography variant="body2" color="textSecondary">
              Best: {habit.longest_streak || 0}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <CheckCircleIcon
              sx={{ color: '#4caf50' }}
              fontSize="small"
            />
            <Typography variant="body2" color="textSecondary">
              {habit.total_completions || 0}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" color="textSecondary">
              {getTimeWindowDisplay()}
            </Typography>
          </Box>
          {habit.requires_scheduling && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AccessTimeIcon fontSize="small" sx={{ mr: 0.5 }} />
              <Typography variant="body2" color="textSecondary">
                {habit.estimated_minutes}min
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="contained"
            color="success"
            size="small"
            fullWidth
            startIcon={<CheckCircleIcon />}
            onClick={() => onComplete(habit)}
            sx={{ boxShadow: 'none' }}
          >
            Completed Today
          </Button>
          <Button
            variant="contained"
            color="warning"
            size="small"
            fullWidth
            startIcon={<SkipNextIcon />}
            onClick={() => onSkip(habit)}
            sx={{ boxShadow: 'none' }}
          >
            Skipped Today
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

const EmptyState = ({ onCreateClick }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '40vh',
      gap: 3,
      textAlign: 'center'
    }}
  >
    <Typography variant="body1" color="text.secondary" sx={{ maxWidth: 300, mb: 2, mt: 2 }}>
      Begin your journey to better habits. Create your first habit to get started.
    </Typography>
    <Button
      variant="contained"
      color="secondary"
      size="large"
      startIcon={<AddIcon />}
      onClick={onCreateClick}
      sx={{
        color: '#ffffff !important',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        boxShadow: 'none',
        px: 4,
        py: 1.5,
        fontSize: '1.1rem',
          borderRadius: 5
      }}
    >
      Create Your First Habit
    </Button>
  </Box>
);

const HabitsPage = () => {
  const { auth } = useAuth();
  const [habits, setHabits] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedHabit, setSelectedHabit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const MAX_FREE_HABITS = 1;
  const MAX_PREMIUM_HABITS = 3;
  const maxHabits = auth?.isSubscribed ? MAX_PREMIUM_HABITS : MAX_FREE_HABITS;

  const fetchHabits = async () => {
    try {
      const response = await axiosInstance.get('/api/habits');
      setHabits(response.data.habits);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch habits');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHabits();
  }, []);

  const handleCreateOpen = () => setCreateModalOpen(true);
  const handleCreateClose = () => setCreateModalOpen(false);
  const handleDetailOpen = (habit) => {
    setSelectedHabit(habit);
    setDetailModalOpen(true);
  };
  const handleDetailClose = () => {
    setSelectedHabit(null);
    setDetailModalOpen(false);
  };

  const handleComplete = (habit) => {
    handleDetailOpen(habit);
  };

  const handleSkip = (habit) => {
    handleDetailOpen(habit);
  };

  if (loading) {
    return (
      <Layout>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <Typography>Loading habits...</Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Box sx={{ p: 3 }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 3
        }}>
          <Box>
            <Typography variant="h4">
              Habits 🌱
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mt: 0.5 }}
            >
              Track and maintain your daily routines with flexible scheduling.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="body1">
              {`${habits.length}/${maxHabits}`}
            </Typography>
            {habits.length >= maxHabits && !auth?.isSubscribed ? (
              <Button
                variant="contained"
                color="primary"
                href="/subscribe"
              >
                Upgrade for More Habits
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleCreateOpen}
                sx={{
                  color: '#ffffff !important',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  boxShadow: 'none'
                }}
                disabled={habits.length >= maxHabits}
              >
                New Habit
              </Button>
            )}
          </Box>
        </Box>

        {habits.length > 0 ? (
          <Grid container spacing={3}>
            {habits.map((habit) => (
              <Grid item xs={12} sm={6} md={4} key={habit.id}>
                <HabitCard
                  habit={habit}
                  onComplete={handleComplete}
                  onEdit={handleDetailOpen}
                  onSkip={handleSkip}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <EmptyState onCreateClick={handleCreateOpen} />
        )}
      </Box>

      {/* Create Habit Modal */}
      <Modal open={createModalOpen} onClose={handleCreateClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add a New Habit
          </Typography>
          <HabitForm onClose={handleCreateClose} refreshHabits={fetchHabits} />
        </Box>
      </Modal>

      {/* Detail/Edit Modal */}
      <Modal open={detailModalOpen} onClose={handleDetailClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <HabitDetailForm
            habit={selectedHabit}
            onClose={handleDetailClose}
            refreshHabits={fetchHabits}
          />
        </Box>
      </Modal>
    </Layout>
  );
};

export default HabitsPage;