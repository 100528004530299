import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import axiosInstance from '../api/axiosInstance';

const COLORS = [
  '#F3A160', // Custom orange
  '#2E3060', // Custom dark blue
  '#C6D8AF', // Custom light green
  '#6CCFF6', // Custom cyan blue
  '#9999C3', // Custom soft purple
  '#FFCC99', // Light peach
  '#A3D9FF', // Soft sky blue
  '#FF9999', // Light red
  '#B5A1FF', // Light violet
  '#FFE599', // Soft yellow
];

function HabitForm({ onClose, refreshHabits, habit }) {
  const { auth } = useAuth();
  const [habitName, setHabitName] = useState(habit ? habit.name : '');
  const [requiresScheduling, setRequiresScheduling] = useState(
    habit ? habit.requires_scheduling : true
  );
  const [estimatedMinutes, setEstimatedMinutes] = useState(
    habit ? habit.estimated_minutes : ''
  );
  const [windowStart, setWindowStart] = useState(habit ? habit.window_start : '09:00');
  const [windowEnd, setWindowEnd] = useState(habit ? habit.window_end : '17:00');
  const [selectedColor, setSelectedColor] = useState(
    habit ? habit.hex_color : COLORS[0]
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    if (habit) {
      setHabitName(habit.name);
      setRequiresScheduling(habit.requires_scheduling);
      setEstimatedMinutes(habit.estimated_minutes || '');
      setSelectedColor(habit.hex_color || COLORS[0]);
      setWindowStart(habit.window_start || '09:00');
      setWindowEnd(habit.window_end || '17:00');
    }
  }, [habit]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');

    if (!habitName.trim()) {
      setErrorMessage('Please provide a habit name.');
      setIsErrorModalOpen(true);
      return;
    }

    if ((!estimatedMinutes || parseInt(estimatedMinutes) <= 0)) {
      setErrorMessage('Please set valid estimated minutes for scheduled habits.');
      setIsErrorModalOpen(true);
      return;
    }

    if (windowStart >= windowEnd) {
      setErrorMessage('End time must be after start time.');
      setIsErrorModalOpen(true);
      return;
    }

    const habitData = {
      name: habitName,
      requires_scheduling: requiresScheduling,
      estimated_minutes: requiresScheduling ? parseInt(estimatedMinutes) : null,
      hex_color: selectedColor,
      window_start: windowStart,
      window_end: windowEnd,
    };

    try {
      if (habit) {
        await axiosInstance.put(`/api/habits/${habit.id}`, habitData);
      } else {
        await axiosInstance.post('/api/habits', habitData);
      }

      if (refreshHabits) {
        refreshHabits();
      }

      if (onClose) {
        onClose();
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error saving the habit. Please try again.');
      setIsErrorModalOpen(true);
    }
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <Box sx={{ maxHeight: '80vh', overflowY: 'auto', overflowX: 'hidden', padding: 2 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Habit Name */}
          <Grid item xs={12}>
            <TextField
              label="Habit Name"
              variant="outlined"
              fullWidth
              value={habitName}
              onChange={(e) => setHabitName(e.target.value)}
              required
            />
          </Grid>

          {/* Scheduling Toggle */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Button
                variant={requiresScheduling ? 'contained' : 'outlined'}
                onClick={() => setRequiresScheduling(true)}
                fullWidth
              >
                Must Schedule Daily
              </Button>
              <Button
                variant={!requiresScheduling ? 'contained' : 'outlined'}
                onClick={() => setRequiresScheduling(false)}
                fullWidth
              >
                Schedule If Available
              </Button>
            </Box>
          </Grid>

          {/* Time Window */}
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
              <AccessTimeIcon sx={{ mr: 1 }} />
              This habit should happen between:
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <TextField
                type="time"
                value={windowStart}
                onChange={(e) => setWindowStart(e.target.value)}
                variant="outlined"
                sx={{ width: '45%' }}
                inputProps={{
                  step: 300, // 5 min intervals
                }}
              />
              <Typography variant="body1">and</Typography>
              <TextField
                type="time"
                value={windowEnd}
                onChange={(e) => setWindowEnd(e.target.value)}
                variant="outlined"
                sx={{ width: '45%' }}
                inputProps={{
                  step: 300, // 5 min intervals
                }}
              />
            </Box>
          </Grid>

          {/* Estimated Minutes (always shown) */}
          <Grid item xs={12}>
            <Tooltip title="How many minutes this habit typically takes" arrow>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <AccessTimeIcon color="action" />
                <TextField
                  label="Estimated Minutes"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={estimatedMinutes}
                  onChange={(e) => setEstimatedMinutes(e.target.value)}
                  required
                />
              </Box>
            </Tooltip>
          </Grid>

          {/* Color Picker */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center">
                {COLORS.map((color) => (
                  <Box
                    key={color}
                    onClick={() => handleColorChange(color)}
                    sx={{
                      width: 24,
                      height: 24,
                      backgroundColor: color,
                      borderRadius: '50%',
                      cursor: 'pointer',
                      border: selectedColor === color ? '2px solid black' : '2px solid transparent',
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {habit ? 'Update Habit' : 'Create Habit'}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Error Modal */}
      <Dialog
        open={isErrorModalOpen}
        onClose={handleCloseErrorModal}
        PaperProps={{
          sx: {
            borderRadius: 12,
            padding: '16px',
          },
        }}
      >
        <DialogTitle sx={{ marginBottom: '16px' }}>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default HabitForm;