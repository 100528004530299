import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Layout from '../components/Layout';
import TaskForm from '../components/TaskForm';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../contexts/AuthContext';

const formatEstimatedTime = (minutes) => {
  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return remainingMinutes > 0
      ? `${hours}h ${remainingMinutes}m`
      : `${hours}h`;
  }
  return `${minutes}m`;
};

const TaskSection = ({ title, tasks, backgroundColor, onTaskComplete, onEdit, onDelete }) => {
  const [open, setOpen] = useState(true);
  const [completionDialogOpen, setCompletionDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const handleTaskClick = (task) => {
    setSelectedTask(task);
    setCompletionDialogOpen(true);
  };

  const handleDeleteClick = (task, event) => {
    event.stopPropagation();
    setSelectedTask(task);
    setDeleteDialogOpen(true);
  };

  const handleEditClick = (task, event) => {
    event.stopPropagation();
    onEdit(task);
  };

  const handleCompleteConfirm = () => {
    if (selectedTask) {
      onTaskComplete(selectedTask);
      setCompletionDialogOpen(false);
      setSelectedTask(null);
    }
  };

  const handleDeleteConfirm = () => {
    if (selectedTask) {
      onDelete(selectedTask);
      setDeleteDialogOpen(false);
      setSelectedTask(null);
    }
  };

  const handleDialogClose = () => {
    setCompletionDialogOpen(false);
    setDeleteDialogOpen(false);
    setSelectedTask(null);
  };

  const getPriorityDisplay = (priority) => {
    switch (priority) {
      case 'urgent_important':
        return 'Urgent & Important';
      case 'important_not_urgent':
        return 'Important but Not Urgent';
      case 'urgent_not_important':
        return 'Urgent but Not Important';
      case 'not_important_not_urgent':
        return 'Not Important or Urgent';
      default:
        return priority;
    }
  };

  const getPriorityStyle = (priority) => {
    switch (priority) {
      case 'urgent_important':
        return {
          backgroundColor: '#ffebee',
          color: '#c62828'
        };
      case 'important_not_urgent':
        return {
          backgroundColor: '#fff3e0',
          color: '#e65100'
        };
      case 'urgent_not_important':
        return {
          backgroundColor: '#e3f2fd',
          color: '#1565c0'
        };
      case 'not_important_not_urgent':
        return {
          backgroundColor: '#f5f5f5',
          color: '#757575'
        };
      default:
        return {
          backgroundColor: '#f5f5f5',
          color: 'text.secondary'
        };
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Paper sx={{ backgroundColor, borderTopLeftRadius: 1, borderTopRightRadius: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 1,
            pl: 2,
            cursor: 'pointer',
          }}
          onClick={() => setOpen(!open)}
        >
          <IconButton size="small">
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
          <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: 500 }}>
            {title.toUpperCase()}
          </Typography>
          <Typography variant="body2" sx={{ ml: 2, color: 'text.secondary' }}>
            {tasks.length} tasks
          </Typography>
        </Box>
      </Paper>

      <Collapse in={open}>
        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" sx={{ width: '140px' }}>Actions</TableCell>
                <TableCell sx={{ width: '20%' }}>Title</TableCell>
                <TableCell sx={{ width: '30%' }}>Description</TableCell>
                <TableCell sx={{ width: '10%' }}>Estimated Time</TableCell>
                <TableCell sx={{ width: '10%' }}>Due Date</TableCell>
                <TableCell>Priority</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task) => (
                <TableRow
                  key={task.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    backgroundColor: task.completed ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
                  }}
                >
                  <TableCell padding="checkbox">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Checkbox
                        checked={task.completed}
                        onChange={() => !task.completed && handleTaskClick(task)}
                        disabled={task.completed}
                      />
                      <IconButton
                        size="small"
                        onClick={(e) => handleEditClick(task, e)}
                        disabled={task.completed}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(e) => handleDeleteClick(task, e)}
                        color="error"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      sx={{ textDecoration: task.completed ? 'line-through' : 'none' }}
                    >
                      {task.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        textDecoration: task.completed ? 'line-through' : 'none'
                      }}
                    >
                      {task.description}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {formatEstimatedTime(task.estimated_minutes)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {task.due_date && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CalendarTodayIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
                        <Typography variant="body2">
                          {new Date(task.due_date).toLocaleDateString()}
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'inline-block',
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        ...getPriorityStyle(task.priority)
                      }}
                    >
                      <Typography variant="body2">
                        {getPriorityDisplay(task.priority)}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>

      {/* Complete Task Dialog */}
      <Dialog
        open={completionDialogOpen}
        onClose={handleDialogClose}
        PaperProps={{ sx: { borderRadius: 2, padding: 1 } }}
      >
        <DialogTitle>Complete Task?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to mark "{selectedTask?.name}" as complete?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button onClick={handleDialogClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleCompleteConfirm} variant="contained" color="primary">
            Complete Task
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Task Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        PaperProps={{ sx: { borderRadius: 2, padding: 1 } }}
      >
        <DialogTitle>Delete Task?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{selectedTask?.name}"?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button onClick={handleDialogClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} variant="contained" color="error">
            Delete Task
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const TasksPage = () => {
  const [tasks, setTasks] = useState({ scheduled: [], unscheduled: [] });
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { auth } = useAuth();

  const MAX_FREE_TASKS = 5;
  const MAX_PREMIUM_TASKS = Infinity; // no limit
  const maxTasks = auth?.isSubscribed ? MAX_PREMIUM_TASKS : MAX_FREE_TASKS;

  const fetchTasks = async () => {
    try {
      const response = await axiosInstance.get('/api/tasks');
      setTasks(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setError('Failed to load tasks');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const handleTaskComplete = async (task) => {
    try {
      await axiosInstance.post(`/api/tasks/${task.id}/complete`);
      fetchTasks();
    } catch (error) {
      console.error('Error completing task:', error);
    }
  };

  const handleTaskDelete = async (task) => {
    try {
      await axiosInstance.delete(`/api/tasks/${task.id}`);
      fetchTasks();
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleTaskEdit = (task) => {
    setSelectedTask(task);
    setCreateModalOpen(true);
  };

  const handleModalClose = () => {
    setCreateModalOpen(false);
    setSelectedTask(null);
  };

  if (loading) {
    return <Layout><Box sx={{ p: 3 }}>Loading tasks...</Box></Layout>;
  }

  if (error) {
    return <Layout><Box sx={{ p: 3 }}>{error}</Box></Layout>;
  }

  const totalTasks = tasks.scheduled.length + tasks.unscheduled.length;

  return (
    <Layout>
      <Box sx={{ p: 3 }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 3
        }}>
          <Box>
            <Typography variant="h4">
              Tasks 📝
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mt: 0.5, mb: 2 }}
            >
              Manage your tasks and schedule them on your calendar.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {!auth?.isSubscribed && (
              <Typography variant="body1">
                {`${totalTasks}/${maxTasks}`}
              </Typography>
            )}
            {totalTasks >= maxTasks && !auth?.isSubscribed ? (
              <Button
                variant="contained"
                color="primary"
                href="/subscribe"
              >
                Upgrade for More Tasks
              </Button>
            ) : (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{
                  color: '#ffffff !important',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  boxShadow: 'none'
                }}
                onClick={() => setCreateModalOpen(true)}
                disabled={(!auth?.isSubscribed && totalTasks >= maxTasks)}
              >
                New Task
              </Button>
            )}
          </Box>
        </Box>

        <TaskSection
          title="Tasks That Require Scheduling"
          tasks={tasks.scheduled}
          backgroundColor="rgba(239, 247, 253, 0.35)"
          onTaskComplete={handleTaskComplete}
          onEdit={handleTaskEdit}
          onDelete={handleTaskDelete}
        />

        <TaskSection
          title="Tasks Without Scheduling"
          tasks={tasks.unscheduled}
          backgroundColor="rgba(252, 237, 252, 0.35)"
          onTaskComplete={handleTaskComplete}
          onEdit={handleTaskEdit}
          onDelete={handleTaskDelete}
        />
      </Box>

      <Modal
        open={createModalOpen}
        onClose={handleModalClose}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <Typography variant="h6" gutterBottom>
            {selectedTask ? 'Edit Task' : 'Add a New Task'}
          </Typography>
          <TaskForm
            onClose={handleModalClose}
            refreshTasks={fetchTasks}
            task={selectedTask}
          />
        </Box>
      </Modal>
    </Layout>
  );
};

export default TasksPage;