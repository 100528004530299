import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Initialize auth state from localStorage
  const [auth, setAuth] = useState(() => {
    const storedAuth = localStorage.getItem('auth');
    return storedAuth
      ? JSON.parse(storedAuth)
      : {
          isLoggedIn: false,
          userEmail: null,
          isSubscribed: false,
          isEmailVerified: false,
          isOnboardingComplete: false,
          calendarType: '',
          isCheckingSession: true,
        };
  });

  // Persist auth state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('auth', JSON.stringify(auth));
  }, [auth]);

  // Initialize Pendo when auth state changes
  useEffect(() => {
    if (auth.isLoggedIn && !auth.isCheckingSession && auth.userEmail) {
      window.pendo?.initialize({
        visitor: {
          id: auth.userEmail,
          email: auth.userEmail,
          isEmailVerified: auth.isEmailVerified,
          calendarType: auth.calendarType || 'none',
          isOnboardingComplete: auth.isOnboardingComplete,
          subscriptionStatus: auth.isSubscribed ? 'subscribed' : 'free'
        },
        account: {
          id: auth.userEmail.split('@')[1],
        }
      });
    } else if (!auth.isLoggedIn && window.pendo) {
      // Clear Pendo data on logout
      window.pendo.initialize({
        visitor: {
          id: null,
        },
        account: {
          id: null
        }
      });
    }
  }, [auth.isLoggedIn, auth.isCheckingSession, auth.userEmail, auth.isEmailVerified,
      auth.calendarType, auth.isOnboardingComplete, auth.isSubscribed]);

  const syncTokens = useCallback((accessToken) => {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }, []);

  const handleLogoutAndRedirect = useCallback(() => {
    // Clear Pendo data before logout
    if (window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: null,
        },
        account: {
          id: null
        }
      });
    }

    setAuth({
      isLoggedIn: false,
      userEmail: null,
      isSubscribed: false,
      isEmailVerified: false,
      isOnboardingComplete: false,
      calendarType: '',
      isCheckingSession: false,
    });
    navigate('/login', { replace: true });
  }, [navigate]);

  const register = async (email, password) => {
    try {
      const response = await axiosInstance.post('/api/user/register', { email, password });

      if (response.status === 201) {
        const { access_token } = response.data;

        setAuth({
          isLoggedIn: true,
          userEmail: email,
          isSubscribed: false,
          isEmailVerified: false,
          isOnboardingComplete: false,
          calendarType: '',
          isCheckingSession: false,
        });

        syncTokens(access_token);

        navigate('/verify');
      } else {
        throw new Error(response.data.message || 'Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Registration error:', error);
      throw new Error(error.response?.data?.message || 'Registration failed. Please try again.');
    }
  };

  const checkSession = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/user/check_session');

      if (response.status === 200 && response.data.isLoggedIn) {
        setAuth((prevAuth) => ({
          ...(prevAuth || {}),
          isLoggedIn: true,
          userEmail: response.data.userEmail,
          isSubscribed: response.data.is_subscribed,
          isEmailVerified: response.data.is_email_verified,
          isOnboardingComplete: response.data.is_onboarding_complete,
          calendarType: response.data.calendar_type || '',
          isCheckingSession: false,
        }));

        if (location.pathname === '/register') {
          if (!response.data.is_email_verified) {
            navigate('/verify', { replace: true });
          } else {
            navigate('/calendar', { replace: true });
          }
        } else if (location.pathname === '/login') {
          navigate('/calendar', { replace: true });
        }
      } else {
        handleLogoutAndRedirect();
      }
    } catch (error) {
      if (error.response?.status === 401 && error.response?.data?.msg === 'Token has expired') {
        try {
          const refreshResponse = await axiosInstance.post('/api/user/refresh_token');
          syncTokens(refreshResponse.data.access_token);
          return checkSession();
        } catch (refreshError) {
          console.error('Refresh token failed, logging out:', refreshError);
          handleLogoutAndRedirect();
        }
      } else {
        console.error('Failed to check session:', error);
        handleLogoutAndRedirect();
      }
    }
  }, [navigate, location.pathname, syncTokens, handleLogoutAndRedirect]);

  const login = async (email, password) => {
    try {
      const response = await axiosInstance.post('/api/user/login', { email, password });

      if (response.status === 200) {
        const { access_token, emailVerified, isSubscribed, isOnboardingComplete, calendar_type } = response.data;

        setAuth({
          isLoggedIn: true,
          userEmail: email,
          isSubscribed: isSubscribed,
          isEmailVerified: emailVerified,
          isOnboardingComplete: isOnboardingComplete || false,
          calendarType: calendar_type || '',
          isCheckingSession: false,
        });

        syncTokens(access_token);

        if (!emailVerified) {
          navigate('/verify');
        } else {
          navigate('/calendar');
        }
      } else {
        throw new Error('Login unsuccessful. Please check your email and password.');
      }
    } catch (error) {
      console.error('Login error:', error.response ? error.response.data.message : error.message);
      setAuth((prevAuth) => ({ ...(prevAuth || {}), isCheckingSession: false }));
      throw new Error(error.response?.data?.message || 'Login failed. Please try again.');
    }
  };

  const logout = async () => {
    try {
      const response = await axiosInstance.post(
        '/api/user/logout',
        {},
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        handleLogoutAndRedirect();
      } else {
        console.error('Logout failed:', response.data.message);
      }
    } catch (error) {
      console.error('Network or other error when trying to logout:', error);
    }
  };

  useEffect(() => {
    const isExcludedPath =
      location.pathname === '/register' ||
      location.pathname === '/login' ||
      location.pathname === '/forgot-password' ||
      location.pathname.startsWith('/reset-password/') ||
      location.pathname === '/reset';

    if (!isExcludedPath) {
      checkSession();
    } else {
      setAuth((prevAuth) => ({ ...(prevAuth || {}), isCheckingSession: false }));
    }
  }, [checkSession, location.pathname]);

  const microsoftSignIn = async () => {
    try {
      const response = await axiosInstance.get('/api/user/outlook/auth');

      if (response.data.authorization_url) {
        window.location.href = response.data.authorization_url;
      } else {
        throw new Error('No authorization URL received from Microsoft authentication');
      }
    } catch (error) {
      console.error('Microsoft auth error:', error);
      throw new Error(
        error.response?.data?.error ||
        error.response?.data?.message ||
        'Failed to connect to Microsoft. Please try again.'
      );
    }
  };

  const googleSignIn = async () => {
    try {
      const response = await axiosInstance.get('/api/user/google/auth');

      if (response.data.authorization_url) {
        window.location.href = response.data.authorization_url;
      } else {
        throw new Error('No authorization URL received from Google authentication');
      }
    } catch (error) {
      console.error('Google auth error:', error);
      throw new Error(
        error.response?.data?.error ||
        error.response?.data?.message ||
        'Failed to connect to Google. Please try again.'
      );
    }
  };

  return (
    <AuthContext.Provider value={{ auth, setAuth, login, logout, checkSession, register, microsoftSignIn, googleSignIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;