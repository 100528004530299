import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import axiosInstance from '../api/axiosInstance';

function formatDateTimeLocal(dateString) {
  if (!dateString) return '';
  const date = new Date(dateString);
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60 * 1000);
  return localDate.toISOString().slice(0, 16);
}

function TaskForm({ onClose, refreshTasks, task }) {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    estimated_minutes: '',
    due_date: '',
    priority: 'important_not_urgent',
    requires_scheduling: true,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    if (task) {
      setFormData({
        name: task.name || '',
        description: task.description || '',
        estimated_minutes: task.estimated_minutes || '',
        due_date: task.due_date ? formatDateTimeLocal(task.due_date) : '',
        priority: task.priority || 'important_not_urgent',
        requires_scheduling: task.requires_scheduling ?? true,
      });
    }
  }, [task]);

  const priorityOptions = [
    { value: 'urgent_important', label: 'Urgent & Important' },
    { value: 'important_not_urgent', label: 'Important but Not Urgent' },
    { value: 'urgent_not_important', label: 'Urgent but Not Important' },
    { value: 'not_important_not_urgent', label: 'Not Important or Urgent' },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');

    if (!formData.name.trim()) {
      setErrorMessage('Please provide a task name.');
      setIsErrorModalOpen(true);
      return;
    }

    if (formData.requires_scheduling && (!formData.estimated_minutes || parseInt(formData.estimated_minutes) <= 0)) {
      setErrorMessage('Please set valid estimated minutes for scheduled tasks.');
      setIsErrorModalOpen(true);
      return;
    }

    const taskData = {
      ...formData,
      estimated_minutes: parseInt(formData.estimated_minutes) || 0,
    };

    try {
      if (task) {
        await axiosInstance.put(`/api/tasks/${task.id}`, taskData);
      } else {
        await axiosInstance.post('/api/tasks', taskData);
      }

      if (refreshTasks) {
        refreshTasks();
      }

      if (onClose) {
        onClose();
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error saving the task. Please try again.');
      setIsErrorModalOpen(true);
    }
  };

  const handleChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  return (
    <Box sx={{ maxHeight: '80vh', overflowY: 'auto', overflowX: 'hidden', padding: 2 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Task Name */}
          <Grid item xs={12}>
            <TextField
              label="Task Name"
              variant="outlined"
              fullWidth
              value={formData.name}
              onChange={handleChange('name')}
              required
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={formData.description}
              onChange={handleChange('description')}
            />
          </Grid>

          {/* Priority */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Priority</InputLabel>
              <Select
                value={formData.priority}
                onChange={handleChange('priority')}
                label="Priority"
              >
                {priorityOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Scheduling Toggle */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Button
                variant={formData.requires_scheduling ? 'contained' : 'outlined'}
                onClick={() => handleChange('requires_scheduling')({ target: { value: true }})}
                fullWidth
              >
                Must Schedule
              </Button>
              <Button
                variant={!formData.requires_scheduling ? 'contained' : 'outlined'}
                onClick={() => handleChange('requires_scheduling')({ target: { value: false }})}
                fullWidth
              >
                No Scheduling Required
              </Button>
            </Box>
          </Grid>

          {/* Estimated Minutes */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AccessTimeIcon color="action" />
              <TextField
                label="Estimated Minutes"
                variant="outlined"
                fullWidth
                type="number"
                value={formData.estimated_minutes}
                onChange={handleChange('estimated_minutes')}
                required={formData.requires_scheduling}
              />
            </Box>
          </Grid>

          {/* Due Date */}
          <Grid item xs={12}>
            <TextField
              label="Due Date"
              type="datetime-local"
              fullWidth
              value={formData.due_date}
              onChange={handleChange('due_date')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {task ? 'Update Task' : 'Create Task'}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Error Modal */}
      <Dialog
        open={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 12,
            padding: '16px',
          },
        }}
      >
        <DialogTitle sx={{ marginBottom: '16px' }}>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsErrorModalOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default TaskForm;