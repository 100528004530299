import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  Alert,
  Snackbar,
  Button,
  Paper,
  CircularProgress
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  Lock as LockIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Layout from '../components/Layout';
import TimeDistributionChart from '../components/analytics/TimeDistributionChart';
import CapacityChart from '../components/analytics/CapacityChart';
import StatSummary from '../components/analytics/StatSummary';
import axiosInstance from '../api/axiosInstance';

// Dummy data for free users
const DUMMY_TIME_DISTRIBUTION = {
  categories: [
    { name: 'Projects', minutes: 480, percentage: 40 },
    { name: 'Tasks', minutes: 360, percentage: 30 },
    { name: 'Habits', minutes: 240, percentage: 20 },
    { name: 'Calendar Events', minutes: 120, percentage: 10 }
  ],
  total_minutes: 1200
};

const DUMMY_CAPACITY_DATA = {
  days: Array.from({ length: 30 }, (_, i) => ({
    date: new Date(2024, 0, i + 1).toISOString().split('T')[0],
    total_capacity_minutes: 480,
    used_capacity_minutes: Math.floor(Math.random() * 300 + 100)
  }))
};

const DUMMY_STATS = {
  total_project_completions: 12,
  total_task_completions: 45,
  total_habit_completions: 120,
  best_habit_streak: 14
};

const AnalyticsPage = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [realData, setRealData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!auth.isSubscribed) {
        setLoading(false);
        return;
      }

      try {
        const { data: response } = await axiosInstance.get('/api/analytics/summary');
        setRealData(response);
      } catch (error) {
        console.error('Error fetching initial analytics data:', error);
        setSnackbar({
          open: true,
          message: 'Failed to load analytics data',
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [auth.isSubscribed]);

  const handleRefresh = async () => {
    if (!auth.isSubscribed) {
      navigate('/subscribe');
      return;
    }

    setRefreshing(true);
    try {
      const { data: response } = await axiosInstance.get('/api/analytics/summary');
      setRealData(response);
      setSnackbar({
        open: true,
        message: 'Analytics refreshed successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error refreshing analytics:', error);
      setSnackbar({
        open: true,
        message: 'Failed to refresh analytics',
        severity: 'error'
      });
    } finally {
      setRefreshing(false);
    }
  };

  const Content = () => {
    // Get appropriate data based on subscription status
    const data = auth.isSubscribed ? realData : {
      timeDistribution: DUMMY_TIME_DISTRIBUTION,
      capacityData: DUMMY_CAPACITY_DATA,
      stats: DUMMY_STATS
    };

    // Show loading state if still loading initial data
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <>
        {/* Stats Summary */}
        <Box>
          <StatSummary data={data?.stats} />
        </Box>

        {/* Time Distribution Chart */}
        <Box>
          <TimeDistributionChart data={data?.timeDistribution} />
        </Box>

        {/* Capacity Chart */}
        <Box>
          <CapacityChart data={data?.capacityData} />
        </Box>
      </>
    );
  };

  return (
    <Layout>
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        {/* Header */}
        <Box
          sx={{
            p: 3,
            borderBottom: 1,
            borderColor: 'divider',
            backgroundColor: 'transparent',
            position: 'sticky',
            top: 0,
            zIndex: 1
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Box>
              <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                Insights 📊
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Track your productivity and time allocation across all activities.
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 1 }}>
              <Tooltip title={auth.isSubscribed ? "Refresh data" : "Upgrade to refresh data"}>
                <span>
                  <IconButton
                    onClick={handleRefresh}
                    disabled={refreshing || !auth.isSubscribed}
                    sx={{
                      transition: 'transform 0.2s',
                      '&:hover': { transform: 'rotate(30deg)' }
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Box>

        {/* Main Content */}
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            position: 'relative',
            zIndex: 0
          }}
        >
          <Content />

          {/* Premium Overlay */}
          {!auth.isSubscribed && (
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: { xs: 0, md: '240px' },
                right: 0,
                bottom: 0,
                backdropFilter: 'blur(8px)',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1200,
                paddingTop: '64px'
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  p: 4,
                  borderRadius: 2,
                  textAlign: 'center',
                  maxWidth: 400,
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)'
                }}
              >
                <LockIcon
                  sx={{
                    fontSize: 40,
                    color: 'primary.main',
                    mb: 2,
                    opacity: 0.9
                  }}
                />
                <Typography variant="h6" sx={{ mb: 1, fontWeight: 500 }}>
                  Unlock your productivity insights
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                  Get detailed insights into your productivity patterns.
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => navigate('/subscribe')}
                  sx={{
                    px: 4,
                    py: 1,
                    borderRadius: 5,
                    textTransform: 'none',
                    fontSize: '1.1rem',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'secondary.main',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.12)',
                      backgroundColor: 'secondary.dark',
                    },
                    transition: 'all 0.2s ease-in-out'
                  }}
                >
                  Upgrade Now
                </Button>
              </Paper>
            </Box>
          )}
        </Box>

        {/* Notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Layout>
  );
};

export default AnalyticsPage;