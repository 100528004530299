import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Grid,
  useTheme,
  Alert
} from '@mui/material';
import axiosInstance from '../../api/axiosInstance';

const StatCard = ({ title, value, color }) => (
  <Paper
    elevation={0}
    sx={{
      p: 3,
      borderRadius: 4,
      backgroundColor: 'white',
      textAlign: 'center',
      transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
      }
    }}
  >
    <Typography
      variant="h5"
      sx={{
        color,
        fontWeight: 600,
        mb: 1
      }}
    >
      {value}
    </Typography>
    <Typography variant="body2" sx={{ color: '#666' }}>
      {title}
    </Typography>
  </Paper>
);

const StatSummary = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: response } = await axiosInstance.get('/api/analytics/summary');
        setData(response);
        setError(null);
      } catch (error) {
        console.error('Error fetching summary stats:', error);
        setError('Failed to load summary data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={200}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Box>
    );
  }

  const {
    total_habit_completions,
    best_habit_streak,
    total_project_completions,
    total_task_completions
  } = data;

  return (
    <Box sx={{ mb: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <StatCard
            title="Project Completions"
            value={total_project_completions}
            color={theme.palette.primary.main}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StatCard
            title="Task Completions"
            value={total_task_completions}
            color={theme.palette.secondary.main}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StatCard
            title="Habit Completions"
            value={total_habit_completions}
            color={theme.palette.success.main}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StatCard
            title="Best Habit Streak"
            value={best_habit_streak}
            color={theme.palette.warning.main}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatSummary;
