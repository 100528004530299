import React, { useState } from 'react';
import { Typography, Button, useTheme, Box, Dialog, CircularProgress } from '@mui/material';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import CalendarToolbar from './CalendarToolbar';
import PropTypes from 'prop-types';
import FloatingSettingsMenu from './FloatingSettingsMenu';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import syncIcon from '../assets/sync.png';
import habitsIcon from '../assets/interface-icons/habits.svg';
import tasksIcon from '../assets/interface-icons/tasks-icon.svg';
import bullseyeIcon from '../assets/interface-icons/bullseye-arrow.svg';
import '../App.css';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 0 }),
  getDay,
  locales,
});

// Color helper functions
const rgbToHsl = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;
  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }
  return [h, s, l];
};

const hslToRgb = (h, s, l) => {
  let r, g, b;
  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1/6) return p + (q - p) * 6 * t;
      if (t < 1/2) return q;
      if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }
  return [r * 255, g * 255, b * 255];
};

const darkenColor = (hex, percent) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  const hsl = rgbToHsl(r, g, b);
  hsl[2] = Math.max(0, hsl[2] * (1 - percent));
  const rgb = hslToRgb(hsl[0], hsl[1], hsl[2]);
  return '#' +
    Math.round(rgb[0]).toString(16).padStart(2, '0') +
    Math.round(rgb[1]).toString(16).padStart(2, '0') +
    Math.round(rgb[2]).toString(16).padStart(2, '0');
};

const LoadingModal = ({ open }) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const messages = [
    "Updating your schedule...",
    "Understanding your calendar...",
    "Feeding the server hamsters..."
  ];

  React.useEffect(() => {
    if (!open) return;
    const interval = setInterval(() => {
      setMessageIndex((prev) => (prev + 1) % messages.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [open]);

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      PaperProps={{
        elevation: 0,
        sx: {
          backgroundColor: 'background.paper',
          borderRadius: 2,
          p: 4,
          minWidth: 300,
        }
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
        <CircularProgress size={48} />
        <Typography variant="body1" sx={{ textAlign: 'center', minWidth: 200, color: 'text.primary' }}>
          {messages[messageIndex]}
        </Typography>
      </Box>
    </Dialog>
  );
};

const CustomHeader = ({ label }) => (
  <Typography
    component="span"
    sx={{
      fontWeight: 700,
      textTransform: 'uppercase',
      letterSpacing: '0.05em',
      padding: '0.2rem',
      display: 'block',
      fontSize: '0.8rem',
      lineHeight: 1.2,
    }}
  >
    {label}
  </Typography>
);

const CustomEvent = ({ event }) => {
  const theme = useTheme();

  let iconSrc;
  switch (event.calendar_type) {
    case 'habit':
      iconSrc = habitsIcon;
      break;
    case 'task':
      iconSrc = tasksIcon;
      break;
    case 'project_task':
      iconSrc = bullseyeIcon;
      break;
    default:
      iconSrc = null;
  }

  const backgroundColor = event.color || theme.palette.primary.main;
  const textColor = darkenColor(backgroundColor, 0.47);

  return (
    <div style={{
      display: 'flex',
      alignItems: 'flex-start', // Changed from center to flex-start
      gap: '4px',
      height: '100%',
      padding: '2px 0', // Added small top/bottom padding
    }}>
      <span style={{
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 4px'
      }}>
        {event.title}
      </span>
      {iconSrc && (
        <img
          src={iconSrc}
          alt=""
          style={{
            width: '12px',
            height: '12px',
            marginRight: '4px',
            marginTop: '2px', // Added to align with text
            filter: 'brightness(0)',
            opacity: textColor === '#ffffff' ? 1 : 0.47
          }}
        />
      )}
    </div>
  );
};

function Calendar({ events, onSync, calendarType, addCustomEventButton, needsUpdate }) {
  const theme = useTheme();
  const [minTime, setMinTime] = useState(8);
  const [maxTime, setMaxTime] = useState(18);
  const [view, setView] = useState('week');
  const [isLoading, setIsLoading] = useState(false);

  const timeToDate = (hours) => {
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  };

  const handleSync = async () => {
    setIsLoading(true);
    try {
      await onSync();
    } finally {
      setIsLoading(false);
    }
  };

  const eventStyleGetter = (event) => {
    const backgroundColor = event.color || theme.palette.primary.main;
    const textColor = darkenColor(backgroundColor, 0.47);

    event.textInvert = textColor === '#ffffff';

    const style = {
      backgroundColor,
      borderRadius: '8px',
      color: textColor,
      border: '0px',
      display: 'block',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      textShadow: '0 1px 1px rgba(0, 0, 0, 0.2)',
      fontSize: '0.85rem',
    };
    return { style };
  };

  const formats = {
    weekdayFormat: (date, culture, localizer) =>
      localizer.format(date, 'EEE d', culture).toUpperCase(),
    dayFormat: (date, culture, localizer) =>
      localizer.format(date, 'EEE d', culture).toUpperCase(),
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: 'calc(100% + 48px)',
        backgroundColor: 'transparent',
        p: 3,
        position: 'relative',
        margin: -3,
        marginRight: -3
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 2, flexShrink: 0 }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {needsUpdate && (
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: '#363838',
                fontWeight: 500,
              }}
            >
              🚩 Hey! You've got pending project changes to schedule →
            </Typography>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={handleSync}
            sx={{
              color: '#ffffff !important',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              boxShadow: 'none'
            }}
          >
            <img
              src={syncIcon}
              alt="sync icon"
              style={{
                width: 16,
                height: 16,
                filter: 'brightness(0) invert(1)'
              }}
            />
            Update Schedule
          </Button>

          {calendarType === 'ics' && addCustomEventButton}
        </Box>
      </Box>

      <LoadingModal open={isLoading} />

      <Box
        sx={{
          flexGrow: 1,
          minHeight: 0,
          position: 'relative',
          '& .rbc-time-header': {
            minHeight: 'unset',
          },
          '& .rbc-time-header-content': {
            minHeight: 'unset',
          },
          '& .rbc-header': {
            minHeight: 'unset',
            padding: '0.4rem !important',
          },
          '& .rbc-time-view': {
            borderTop: '1px solid #ddd',
          },
          '& .rbc-time-gutter .rbc-timeslot-group': {
            '& .rbc-time-slot': {
              color: '#2D3748',
              fontWeight: 500,
              fontSize: '0.85rem',
            }
          },
          '& .rbc-event': {
            minHeight: '24px',
            lineHeight: '24px',
            padding: '0',
          },
          '& .rbc-event-content': {
            height: '100%',
          },
          '& .rbc-event-label': {
              padding: '0 4px',
            },
          '& .rbc-time-header.rbc-overflowing': {
            border: 'none',
          },
        }}
      >
        <BigCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          formats={formats}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          view={view}
          views={['month', 'week', 'day']}
          dayLayoutAlgorithm="no-overlap"
          eventPropGetter={eventStyleGetter}
          min={timeToDate(minTime)}
          max={timeToDate(maxTime)}
          onView={setView}
          components={{
            toolbar: CalendarToolbar,
            header: CustomHeader,
            event: CustomEvent,
          }}
        />
      </Box>

      <FloatingSettingsMenu
        minTime={minTime}
        maxTime={maxTime}
        onMinTimeChange={setMinTime}
        onMaxTimeChange={setMaxTime}
      />
    </Box>
  );
}

Calendar.propTypes = {
  events: PropTypes.array.isRequired,
  onSync: PropTypes.func.isRequired,
  calendarType: PropTypes.string.isRequired,
  addCustomEventButton: PropTypes.node,
  needsUpdate: PropTypes.bool,
};

Calendar.defaultProps = {
  needsUpdate: false,
};

export default Calendar;