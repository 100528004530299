import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Typography,
  Modal,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Paper,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import ProjectForm from '../components/ProjectForm';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../contexts/AuthContext';

const calculateCompletionPercentage = (tasks) => {
  const totalMinutes = tasks.reduce(
    (total, task) => total + task.estimated_hours * 60 + task.estimated_minutes,
    0
  );
  const completedMinutes = tasks.reduce(
    (completed, task) =>
      task.completed
        ? completed + task.estimated_hours * 60 + task.estimated_minutes
        : completed,
    0
  );
  return totalMinutes > 0 ? (completedMinutes / totalMinutes) * 100 : 0;
};

const getProjectStatus = (project) => {
  const now = new Date();
  const startDate = project.start_time ? new Date(project.start_time) : new Date();
  const deadline = new Date(project.deadline);

  if (now < startDate) {
    return 'yet-to-start';
  } else if (now > deadline) {
    return 'overdue';
  } else {
    return 'in-progress';
  }
};

// Convert the hex color to an RGBA with 20% opacity
function getBgColor(hexColor) {
  if (!hexColor || hexColor.length !== 7) return 'rgba(0,0,0,0.1)';
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, 0.4)`;
}

const ProjectCard = ({ project, onEditProject, onDeleteProject }) => {
  const completion = calculateCompletionPercentage(project.tasks);
  const bgColor = getBgColor(project.color);

  return (
    <Card
      sx={{
        borderRadius: 2,
        mb: 1,
        boxShadow: 1,
        overflow: 'visible',
        '&:last-child': { mb: 0 }
      }}
    >
      {/* Colored container */}
      <Box
        sx={{
          borderRadius: 2,
          p: 2,
          mb: 1,
          mx: -2,
          my: -2,
          backgroundColor: bgColor,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {project.name}
          </Typography>
          <Box>
            <IconButton
              onClick={() => onEditProject(project)}
              aria-label="Edit"
              sx={{ ml: 1 }}
            >
              <EditIcon sx={{ color: 'text.secondary' }} />
            </IconButton>
            <IconButton
              onClick={() => onDeleteProject(project)}
              aria-label="Delete"
              sx={{ ml: 1 }}
            >
              <DeleteOutlineIcon sx={{ color: 'text.secondary' }} />
            </IconButton>
          </Box>
        </Box>

        {/* Progress bar */}
        <Box sx={{ mb: 3, mx: 1, my: 1, mt: 2 }}>
          <Box
            sx={{
              height: 9,
              backgroundColor: 'rgba(255,255,255,0.5)',
              borderRadius: 4,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: `${completion}%`,
                bgcolor: project.color,
                transition: 'width 0.3s',
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
            <Typography variant="body2" color="textSecondary">
              Progress
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {Math.round(completion)}%
            </Typography>
          </Box>
        </Box>

        {/* Tasks and Deadline on the same line */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: 'text.primary',
            mt: 2
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ArticleOutlinedIcon sx={{ fontSize: 18 }} />
            <Typography variant="body2" color="textPrimary">
              {project.tasks.length} Tasks
            </Typography>
          </Box>
          <Typography variant="body2" color="textPrimary">
            Deadline: {new Date(project.deadline).toLocaleDateString()}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

const KanbanColumn = ({ title, projects, onEditProject, onDeleteProject, backgroundColor }) => {
  return (
    <Paper
      sx={{
        height: '100%',
        backgroundColor,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Typography variant="h6">
          {title} ({projects.length})
        </Typography>
      </Box>
      <Box
        sx={{
          p: 4,
          overflowY: 'auto',
          flexGrow: 1,
          '&::-webkit-scrollbar': { width: '0.4em' },
          '&::-webkit-scrollbar-track': { background: 'transparent' },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: 2
          }
        }}
      >
        {projects.map((project) => (
          <Box
            key={project.id}
            sx={{
              position: 'relative',
              mb: 2,
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 14,
                bottom: 14,
                right: -1,
                width: '4px',
                borderRadius: '2px',
              }
            }}
          >
            <ProjectCard
              project={project}
              onEditProject={onEditProject}
              onDeleteProject={onDeleteProject}
            />
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

const CompletedColumn = ({ projects, onEditProject, onDeleteProject }) => (
  <Paper
    sx={{
      backgroundColor: '#f0f7f0',
      borderRadius: 2,
      p: 2,
      mt: 3,
      width: '100%'
    }}
  >
    <Typography variant="h6" sx={{ mb: 2 }}>
      Completed Projects ({projects.length})
    </Typography>
    <Grid container spacing={2}>
      {projects.map((project) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={project.id}>
          <ProjectCard
            project={project}
            onEditProject={onEditProject}
            onDeleteProject={onDeleteProject}
          />
        </Grid>
      ))}
    </Grid>
  </Paper>
);

function ProjectsPage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [editProject, setEditProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);

  const MAX_FREE_PROJECTS = 2;

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditProject(null);
  };

  const handleEditProject = async (project) => {
    try {
      const response = await axiosInstance.get(`/api/projects/${project.id}`);
      setEditProject(response.data);
      setOpen(true);
    } catch (err) {
      console.error('Failed to fetch project details:', err);
      setError('Failed to fetch project details.');
    }
  };

  const handleDeleteProject = async () => {
    try {
      await axiosInstance.delete(`/api/projects/${projectToDelete.id}`);
      setProjects((prevProjects) =>
        prevProjects.filter((proj) => proj.id !== projectToDelete.id)
      );
      setDeleteDialogOpen(false);
      setProjectToDelete(null);
    } catch (err) {
      console.error('Failed to delete project:', err);
      setError('Failed to delete project.');
    }
  };

  const handleOpenDeleteDialog = (project) => {
    setProjectToDelete(project);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setProjectToDelete(null);
  };

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get('/api/projects');
      setProjects(response.data.projects);
      setLoading(false);
    } catch (err) {
      setError(`Failed to fetch projects. Error: ${err.message}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  if (loading) {
    return (
      <Layout>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>Loading projects...</Typography>
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Typography color="error">{error}</Typography>
      </Layout>
    );
  }

  const organizeProjects = () => {
    const uncompleted = projects.filter(p => !p.completed);
    return {
      yetToStart: uncompleted.filter(p => getProjectStatus(p) === 'yet-to-start'),
      inProgress: uncompleted.filter(p => getProjectStatus(p) === 'in-progress'),
      overdue: uncompleted.filter(p => getProjectStatus(p) === 'overdue'),
      completed: projects.filter(p => p.completed)
    };
  };

  const { yetToStart, inProgress, overdue, completed } = organizeProjects();
  const currentProjects = [...yetToStart, ...inProgress, ...overdue];

  return (
    <Layout>
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        {/* Fixed Header */}
        <Box
          sx={{
            p: 3,
            borderBottom: 1,
            borderColor: 'divider',
            backgroundColor: 'transparent',
            position: 'sticky',
            top: 0,
            zIndex: 1100
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
              <Typography variant="h4">
                  Assignment Board 📋
                </Typography>
              <Typography variant="body1" color="text.secondary">
                Keep track of your current projects and assignments.
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {!auth.isSubscribed && (
                <Typography variant="body1">
                  {`${currentProjects.length}/${MAX_FREE_PROJECTS}`}
                </Typography>
              )}
              {!auth.isSubscribed && currentProjects.length >= MAX_FREE_PROJECTS ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/subscribe')}
                >
                  Upgrade
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  sx={{
                      color: '#ffffff !important',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      boxShadow: 'none'
                    }}
                  startIcon={<AddIcon />}
                >
                  New Assignment
                </Button>
              )}
            </Box>
          </Box>
        </Box>

        {/* Scrollable Content */}
        <Box sx={{ flexGrow: 1, overflow: 'auto', p: 3 }}>
          {/* Kanban Board */}
          <Grid container spacing={3} sx={{ height: 'auto', minHeight: '500px' }}>
            <Grid item xs={12} md={4}>
              <KanbanColumn
                title="To Start"
                projects={yetToStart}
                onEditProject={handleEditProject}
                onDeleteProject={handleOpenDeleteDialog}
                backgroundColor="rgba(239, 247, 253, 0.35)"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <KanbanColumn
                title="In Progress"
                projects={inProgress}
                onEditProject={handleEditProject}
                onDeleteProject={handleOpenDeleteDialog}
                backgroundColor="rgba(252, 237, 252, 0.35)"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <KanbanColumn
                title="Overdue"
                projects={overdue}
                onEditProject={handleEditProject}
                onDeleteProject={handleOpenDeleteDialog}
                backgroundColor="rgba(255, 235, 238, 0.35)"
              />
            </Grid>
          </Grid>

          {/* Completed Projects Section */}
          {completed.length > 0 && (
            <CompletedColumn
              projects={completed}
              onEditProject={handleEditProject}
              onDeleteProject={handleOpenDeleteDialog}
            />
          )}
        </Box>
      </Box>

      {/* Add/Edit Project Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            {editProject ? 'Edit Project' : 'Add a New Project'}
          </Typography>
          <ProjectForm onClose={handleClose} refreshProjects={fetchProjects} project={editProject} />
        </Box>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this project? This action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 2, justifyContent: 'space-between' }}>
          <Button onClick={handleCloseDeleteDialog} color="inherit" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleDeleteProject} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}

export default ProjectsPage;
