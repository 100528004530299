import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Box,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import Calendar from '../components/Calendar';
import Layout from '../components/Layout';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../contexts/AuthContext';
import AddCustomEventForm from '../components/CustomEvent';
import Step1WorkSetup from '../components/Step1WorkSetup';

function Dashboard() {
  const { auth, setAuth } = useAuth();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [needsUpdate, setNeedsUpdate] = useState(false);

  // Onboarding state
  const [workStartTime, setWorkStartTime] = useState('');
  const [workEndTime, setWorkEndTime] = useState('');
  const [timezone, setTimezone] = useState('');
  const [workdays, setWorkdays] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });

  // Function to complete the onboarding process
  const completeOnboarding = async () => {
    try {
      await axiosInstance.post('/api/user/complete-onboarding', null, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setAuth((prev) => ({ ...prev, isOnboardingComplete: true }));
    } catch (error) {
      console.error('Error completing onboarding:', error);
    }
  };

  // Fetch events from the backend
  const fetchEvents = async () => {
    setLoading(true);
    setErrorMessage('');

    try {
      const response = await axiosInstance.get('/api/outlook/events');
      const eventsData = response.data.events.map((event) => ({
        title: event.title,
        start: new Date(event.start),  // Changed from start_time
        end: new Date(event.end),      // Changed from end_time
        calendar_type: event.calendar_type,
        color: event.color,
      }));

      setEvents(eventsData);
      setNeedsUpdate(response.data.needs_update);
    } catch (err) {
      console.error('Error fetching events:', err);
      setErrorMessage('Failed to load events');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.isLoggedIn) {
      fetchEvents();
      // Set up periodic check for updates
      const interval = setInterval(fetchEvents, 5 * 60 * 1000); // Check every 5 minutes
      return () => clearInterval(interval);
    }
  }, [auth.isLoggedIn]);

  // Function to handle adding a custom event (for ICS calendar type)
  const handleAddCustomEvent = () => {
    setIsAddEventModalOpen(true);
  };

  // Function to handle synchronization (for Outlook calendar type)
  const handleSync = async () => {
    try {
      const response = await axiosInstance.get('/api/sync-calendar');
      console.log('Sync successful', response.data);

      if (response.data.events) {
        const eventsData = response.data.events.map((event) => ({
          title: event.title,
          start: new Date(event.start),     // Changed from start_time
          end: new Date(event.end),         // Changed from end_time
          color: event.color,
        }));
        setEvents(eventsData);
        setNeedsUpdate(false);  // Clear the update flag after successful sync
      } else {
        await fetchEvents();
      }
    } catch (err) {
      console.error('Error during sync:', err);
      setErrorMessage('Failed to sync calendar');
    }
  };

  return (
    <Layout>
      {/* Onboarding Modal */}
      <Modal
        open={!auth.isOnboardingComplete}
        onClose={() => {}}
        disableEscapeKeyDown={true}
        BackdropProps={{
          style: { pointerEvents: 'none' },
        }}
      >
        <Box
          sx={{
            width: '500px',
            margin: '100px auto',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
          }}
        >
          <Step1WorkSetup
            completeOnboarding={completeOnboarding}
            setWorkStartTime={setWorkStartTime}
            setWorkEndTime={setWorkEndTime}
            setTimezone={setTimezone}
            setWorkdays={setWorkdays}
            workStartTime={workStartTime}
            workEndTime={workEndTime}
            timezone={timezone}
            workdays={workdays}
          />
        </Box>
      </Modal>

      {/* Error Modal */}
      <Dialog
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage('')}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="error-dialog-description">{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMessage('')} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Custom Event Modal */}
      <Dialog open={isAddEventModalOpen} onClose={() => setIsAddEventModalOpen(false)}>
        <DialogTitle>Add Custom Event</DialogTitle>
        <DialogContent>
          <AddCustomEventForm onClose={() => setIsAddEventModalOpen(false)} refreshEvents={fetchEvents} />
        </DialogContent>
      </Dialog>

      {/* Dashboard Content */}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100vh' }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : (
          <Calendar
            events={events}
            onSync={handleSync}
            calendarType={auth.calendarType}
            needsUpdate={needsUpdate}
            addCustomEventButton={
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddCustomEvent}
                sx={{ color: '#ffffff !important' }}
              >
                Add Custom Event
              </Button>
            }
          />
        )}
      </Box>
    </Layout>
  );
}

export default Dashboard;